import React from 'react';
import {timeFormat, timeParse} from 'd3-time-format';

import {DateContainer} from './styles';

const SaveDateStyled = (props) => {
    const {date: dateString} = props;

    if (!dateString) return null;

    let prefix = 'Last saved';

    if (props.prefix) prefix = props.prefix;
    if (props.prefix === false) prefix = '';

    const hasTimezone = !dateString.slice(dateString.length - 4).match(/\d/);
    const timezone = dateString.slice(-3);
    const withoutTimezone = hasTimezone ? dateString.slice(0, -4) : dateString;

    const timeObj = timeParse('%Y-%m-%d %I:%M:%S')(withoutTimezone);

    const dateSpecifier = '%m/%d/%Y';
    const formattedTime = timeFormat('%-I:%M%p')(timeObj);
    let formattedDate = timeFormat(dateSpecifier)(timeObj);

    if (formattedDate === timeFormat(dateSpecifier)(new Date())) {
        formattedDate = 'Today';
    }

    if (prefix && formattedDate !== 'Today') prefix = prefix + ' on';

    if (props.dateOnly) {
        return (
            <DateContainer
                $css={props.$css}
                className={props.className}>{`${formattedDate}`}</DateContainer>
        );
    }

    return (
        <DateContainer $css={props.$css} className={props.className}>
            {`${prefix} ${formattedDate} at ${formattedTime} ${
                hasTimezone ? timezone : ''
            }`.trim()}
        </DateContainer>
    );
};

export default SaveDateStyled;
