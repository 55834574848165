import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'utils/helpers';

import {getVipPackages, updatePackageFilter} from 'features/vip/actions';
import {selectVipPackagesByRegion} from 'features/vip/selectors';
import VipPackage from 'features/vip/components/VipPackage/VipPackage';
import {useParams} from 'next/navigation';

let VipPackageContainer = (props) => {
    const [attaching, setAttaching] = useState(false);
    const [activeModalId, setActiveModalId] = useState(null);

    const {listingId} = useParams;

    useEffect(() => {
        props.getVipPackages();
    }, []);

    const handleToggleAttach = () => setAttaching(true);

    const handleAttach = (id) => {
        setActiveModalId((prevActiveModalId) => {
            return prevActiveModalId === id ? null : id;
        });
    };
    return (
        <VipPackage
            listingId={listingId}
            {...props}
            attaching={attaching}
            activeModalId={activeModalId}
            handleToggleAttach={handleToggleAttach}
            handleAttach={handleAttach}
        />
    );
};

VipPackageContainer = connect(
    (state, ownProps) => ({
        packageFilter: state.vip.packageFilter,
        packagesByRegion: selectVipPackagesByRegion(state),
        activeId: state.vip.activeId,
        isFetching: state.vip.isFetching,
    }),
    {
        updatePackageFilter,
        getVipPackages,
    },
)(VipPackageContainer);

export default withRouter(VipPackageContainer);
