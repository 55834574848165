import React from 'react';

import TableArrow from 'assets/img/icons/table-arrow.svg';

import * as styles from './styles';
import {
    ArrowContainer,
    ArrowSvgStyled,
    CellInnerStyled,
    HeaderCellStyled,
    HeaderRowStyled,
    RowDiv,
    TableCellDiv,
} from './styles';

export const TableCell = (props) => {
    return (
        <div
            style={{...styles.cell(props), ...(props.flex ? {flex: props.flex} : {})}}
            onClick={props.handleClick}>
            {props.children}
        </div>
    );
};

export const TableCellStyled = (props) => {
    return (
        <TableCellDiv
            $css={props.$css}
            className={props.className}
            flex={props.flex}
            onClick={props.handleClick}>
            {props.children}
        </TableCellDiv>
    );
};

export const TableHeaderRow = (props) => {
    return (
        <div style={styles.headerRow}>
            {React.Children.map(props.children, (child, i) => {
                return React.cloneElement(child, {
                    ...props.childStyle,
                    activeKey: props.activeKey,
                    handleClick: props.handleClick,
                });
            })}
        </div>
    );
};

export const TableHeaderRowStyled = (props) => {
    return (
        <HeaderRowStyled>
            {React.Children.map(props.children, (child, i) => {
                return React.cloneElement(child, {
                    ...props.childStyle,
                    activeKey: props.activeKey,
                    handleClick: props.handleClick,
                });
            })}
        </HeaderRowStyled>
    );
};

export const TableRow = (props) => <div style={styles.row(props)}>{props.children}</div>;

export const TableRowStyled = (props) => {
    return (
        <RowDiv $css={props.$css} className={props.className}>
            {props.children}
        </RowDiv>
    );
};

export const TableHeaderCell = (props) => {
    const {handleClick, sortKey, children, flex} = props;

    return (
        <HeaderCellStyled
            onClick={() => (sortKey ? handleClick(sortKey) : null)}
            flex={flex}>
            <div style={styles.cellInner}>{children}</div>
            {sortKey && (
                <div style={styles.arrow(props)}>
                    <TableArrow style={styles.arrowSvg} />
                </div>
            )}
        </HeaderCellStyled>
    );
};

export const TableHeaderCellStyled = (props) => {
    const {handleClick, sortKey, children, flex} = props;

    return (
        <HeaderCellStyled
            onClick={() => (sortKey ? handleClick(sortKey) : null)}
            flex={flex}>
            <CellInnerStyled>{children}</CellInnerStyled>
            {sortKey && (
                <ArrowContainer>
                    <ArrowSvgStyled />
                </ArrowContainer>
            )}
        </HeaderCellStyled>
    );
};
