import Link from 'next/link';

import {COLORS, bp} from 'core/styles';
import styled, {css} from 'styled-components';
import StarSvg from 'assets/img/icons/review_star.svg';

export const VipListOuter = styled.div`
    flex: 2;
    margin-right: 0;
    padding: 0;

    ${bp('md')} {
        margin-right: 30px;
    }
`;
export const HeaderDiv = styled.div`
    background-color: ${COLORS.grayDark};
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const HeaderTitle = styled.div`
    font-size: 20px;
    color: #fff;
    display: inline-block;
`;

export const Star = styled(StarSvg)`
    position: relative;
    margin-right: 15px;
    top: 2px;
    height: 18px;
    width: 18px;
    display: inline-block;

    & * {
        fill: #f0a611;
    }
`;

export const HeaderCellCss = css`
    padding-top: 15px;
    padding-bottom: 15px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
`;

export const HeaderRowCss = css`
    background-color: #fff;
`;

export const ListError = styled.div`
    padding: 15px;
    color: ${COLORS.danger};
    font-weight: bold;
`;

export const ListRowCss = css`
    padding-top: 10px;
    padding-bottom: 10px;
`;

export const AccountNameLink = styled(Link)`
    color: ${COLORS.grayFont};
    text-decoration: underline;
    font-size: 11px;
    font-weight: 500;
`;

export const ListLoadingCss = css`
    max-width: 20px;
`;

export const EmptyList = styled.div`
    color: ${COLORS.grayFont};
    font-weight: bold;
    padding: 15px;
`;

export const TileStyleCss = css`
    padding: 0px;
`;
