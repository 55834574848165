import {COLORS, darken} from 'core/styles';
import styled from 'styled-components';

export let header = {
    backgroundColor: COLORS.grayDark,
    color: '#fff',
    padding: '15px',
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
};

export let sectionHeader = (section, active) => {
    return {
        borderLeft: active ? `3px solid ${COLORS.primary}` : '3px solid transparent',
        backgroundColor: active ? COLORS.gray : '#fff',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '15px',
        cursor: 'pointer',

        '&:hover': {
            backgroundColor: active ? COLORS.gray : COLORS.grayLight,
        },

        '&:active': {
            backgroundColor: active ? COLORS.gray : darken(COLORS.grayLight, 5),
        },
    };
};

const getBackgroundColorAvailable = (props, unavailable) => {
    if (props.attach && props.attaching) {
        return unavailable ? COLORS.grayMedium : COLORS.primary;
    }
    return COLORS.grayFont;
};

export let available = (props, unavailable) => {
    return {
        marginRight: '10px',
        color: '#fff',
        backgroundColor: getBackgroundColorAvailable(props, unavailable),
        fontWeight: 'bold',
        fontSize: '11px',
        width: '25px',
        height: '25px',
        borderRadius: '50%',
        textAlign: 'center',
        paddingTop: '5px',
    };
};

export let key = (active) => {
    return {
        fontWeight: 'bold',
        marginRight: '15px',
        textDecoration: active ? 'none' : 'underline',
        color: active ? '#fff' : COLORS.grayFont,
        lineHeight: 1,
    };
};

export let packageFilter = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '5px',
};

export let filter = (props, filter) => {
    return {
        cursor: 'pointer',
        marginRight: '15px',
        textTransform: 'uppercase',
        color: props.packageFilter === filter ? '#fff' : COLORS.grayFont,
        textDecoration: props.packageFilter === filter ? 'none' : 'underline',
        fontSize: '10px',
        fontWeight: 'bold',
    };
};

export let item = (props, pkg) => {
    let styles = {
        padding: '15px',
        borderBottom: `1px solid ${COLORS.grayLight}`,
        opacity: 1,
        cursor: props.attach ? 'pointer' : 'default',
    };

    const counts = pkg.subscription_counts;

    if (counts.active >= 8) {
        styles = {
            ...styles,
            cursor: 'pointer',
        };
    }

    return styles;
};

export let itemTop = {
    display: 'flex',
    justifyContent: 'space-between',
};

const getStateTextColor = (props, unavailable) => {
    if (props.attach && props.attaching) {
        return unavailable ? COLORS.grayMedium : COLORS.primary;
    }
    return COLORS.grayFont;
};

const getMoneyTextColor = (props, unavailable) => {
    if (props.attach && props.attaching) {
        return unavailable ? COLORS.grayMedium : COLORS.gray;
    } else {
        return COLORS.grayFont;
    }
};

export let itemLabel = (props, unavailable) => {
    const showStyle = props.attach && props.attaching && !unavailable;
    return {
        fontSize: '13px',
        fontWeight: 'bold',
        color: getStateTextColor(props, unavailable),
        textDecoration: showStyle ? 'underline' : 'none',
    };
};

export let itemAmount = (props, unavailable) => {
    return {
        fontSize: '14px',
        fontWeight: 'bold',
        color: getMoneyTextColor(props, unavailable),
    };
};

export let states = {
    marginTop: '10px',
};

export let state = {
    display: 'inline-block',
    color: COLORS.grayFont,
    fontWeight: 500,
};

export let statesHeader = {
    backgroundColor: COLORS.grayLight,
    padding: '15px',
};

export let unavailable = {
    fontSize: '9px',
    color: COLORS.grayFont,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    lineHeight: 2.15,
};

export let attach = {
    cursor: 'pointer',
    color: COLORS.primary,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    padding: '15px',
    fontSize: '11px',
    '&:hover': {
        backgroundColor: COLORS.grayLight,
    },

    '&:active': {
        backgroundColor: darken(COLORS.grayLight, 5),
    },
};

export const TooltipText = styled.div``;

export const TooltipBox = styled.div`
    position: absolute;
    top: calc(100% + 10px);
    left: -38px;
    z-index: 50;
    visibility: hidden;
    color: #fff;
    background-color: transparent;
    width: 150px;
    padding: 5px 5px;
    border-radius: 4px;
    transition: visibility 0.2s, color 0.2s, background-color 0.2s, width 0.2s,
        padding 0.2s ease-in-out;
    &:before {
        content: '';
        width: 0;
        height: 0;
        left: 40px;
        top: -10px;
        position: absolute;
        border: 10px solid transparent;
        transform: rotate(135deg);
        transition: border 0.3s ease-in-out;
    }
`;

export const TooltipCard = styled.div`
    position: relative;
    & ${TooltipText}:hover + ${TooltipBox} {
        visibility: visible;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.9);
        width: 230px;
        padding: 8px 8px;
        &:before {
            border-color: transparent transparent rgba(0, 0, 0, 0.9) rgba(0, 0, 0, 0.9);
        }
    }
`;

export const TooltipCardCounts = styled(TooltipCard)`
    margin-left: auto;
    margin-right: 30px;
`;

export let StateTooltip = styled.div`
    display: 'inline-block',
    color: #fff,
    fontWeight: 500
`;

export const ItemLabel = styled.div`
    font-size: 13px;
    font-weight: bold;
    color: ${COLORS.primary};
    text-decoration: underline;
`;
