import React from 'react';
import Link from 'next/link';

import EditModalContainer from 'features/vip/containers/EditModalContainer';
import {get, formatDollars, getStateName} from 'utils/helpers';
import {TileStyled} from 'features/generic/components/Tile/Tile';

import * as styles from './styles';
import {
    PackageTooltip,
    PackageTooltipText,
    StateTooltip,
    TooltipBox,
    TooltipCard,
    TooltipCardCounts,
    TooltipText,
} from './styles';

const VipPackage = (props) => {
    const packages = props.packagesByRegion;

    const getValue = (pkg) => get(pkg, 'region.name');

    const showPackages = props.attach ? props.attaching : true;

    return (
        <TileStyled $css={props.$css} className={props.className}>
            <div style={styles.header}>Packages</div>
            {props.attach && !props.attaching && (
                <div style={styles.attach} onClick={props.handleToggleAttach}>
                    + Attach Package
                </div>
            )}
            <div>
                {showPackages &&
                    packages.map((section) => {
                        const active = props.activeId === section.key;
                        const hasActivePackage = section.values.find((val) => {
                            const counts = val.subscription_counts;

                            return counts.active;
                        });
                        return (
                            <div key={section.key}>
                                {section.values.map((pkg) => {
                                    const counts = pkg.subscription_counts;
                                    const unavailable = counts.active >= 8;
                                    return (
                                        <div
                                            style={styles.item(props, pkg)}
                                            key={pkg.id}
                                            onClick={() =>
                                                !unavailable &&
                                                props.attach &&
                                                props.handleAttach(pkg.id)
                                            }>
                                            <div style={styles.itemTop}>
                                                <TooltipCard>
                                                    <TooltipText>
                                                        <div
                                                            style={styles.itemLabel(
                                                                props,
                                                                unavailable,
                                                            )}>
                                                            {getValue(pkg)}
                                                        </div>
                                                    </TooltipText>
                                                    {get(
                                                        section.values[0],
                                                        'region.states',
                                                    ).length > 1 && (
                                                        <TooltipBox>
                                                            {get(
                                                                section.values[0],
                                                                'region.states',
                                                            ).map((state, i) => (
                                                                <StateTooltip key={state}>
                                                                    {`${getStateName(
                                                                        state,
                                                                    )}${
                                                                        get(
                                                                            section
                                                                                .values[0],
                                                                            'region.states',
                                                                        ).length !==
                                                                        i + 1
                                                                            ? ','
                                                                            : ''
                                                                    }`}
                                                                    &nbsp;
                                                                </StateTooltip>
                                                            ))}
                                                        </TooltipBox>
                                                    )}
                                                </TooltipCard>
                                                <TooltipCardCounts>
                                                    <TooltipText>
                                                        <div
                                                            style={styles.available(
                                                                props,
                                                                unavailable,
                                                            )}>
                                                            {
                                                                pkg?.subscription_counts
                                                                    ?.active
                                                            }
                                                        </div>
                                                    </TooltipText>
                                                    <TooltipBox>
                                                        {pkg?.subscription_counts?.active}{' '}
                                                        of 8 active subscriptions
                                                    </TooltipBox>
                                                </TooltipCardCounts>
                                                <div
                                                    style={styles.itemAmount(
                                                        props,
                                                        unavailable,
                                                    )}>
                                                    {formatDollars(
                                                        get(pkg, 'base_price'),
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
            </div>
            {props.activeModalId && (
                <EditModalContainer
                    packageId={props.activeModalId}
                    handleClose={() => props.handleAttach(props.activeModalId)}
                />
            )}
        </TileStyled>
    );
};

export default VipPackage;
